import { useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import MainPresenter from "../components/main/MainPresenter";
import { linkAPI } from "api";
import useAxios from "modules/customHooks/useAxios";
import { useHistory } from "react-router";

const MainContainer = (): any => {
  const history = useHistory();
  amplitude.init("b83ffe3d901ab794a2328f98499b458b", undefined, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
  amplitude.track("App Open");

  const {
    loading: faviewURLDataLoading,
    data: faviewURLData,
    error: faviewURLDataError,
  } = useAxios(linkAPI.getLink, "?title=FAVIEW_PROPOSAL_LINK");

  const onClickMainLogoButton = (): void => {
    history.push("/");
  };
  const onClickAppDownloadButton = (): void => {
    const eventProperties = { Class: "app_download" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://share.favapp.io/app`, "_blank");
  };
  const onClickFAVHomepageButton = (): void => {
    const eventProperties = { Class: "homepage" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://favapp.co.kr`, "_blank");
  };
  const onClickFAVTeampageButton = (): void => {
    const eventProperties = { Class: "teampage" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://favapp.oopy.io`, "_blank");
  };
  const onClickInstaCollabButton = (): void => {
    const emailTo = "support@favapp.io";
    const emailCC = "a";
    const emailSub = "인스타그램 협업 문의 드립니다.";
    const emailBody =
      "favkorea 인스타그램 컨텐츠 관련 문의 내용을 해당 본문에 적어서 편하게 문의 주세요 :)";
    const eventProperties = { Class: "insta_collab" };
    amplitude.track("Click Button", eventProperties);
    window.open(
      "mailto:" + emailTo + "?subject=" + emailSub + "&body=" + emailBody,
      "_blank"
    );
  };

  const onClickFAVInstagramButton = (): void => {
    const eventProperties = { Class: "instagram" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://www.instagram.com/favkorea/`, "_blank");
  };
  const onClickFAVIEWProposalButton = (): void => {
    const eventProperties = { Class: "faview_proposal" };
    amplitude.track("Click Button", eventProperties);
    window.open(faviewURLData?.data?.url, "_blank");
  };
  const onClickFAVIEWExpButton = (): void => {
    const eventProperties = { Class: "faview_exp" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://brand.faview.io`, "_blank");
  };
  const onClickYoutubeButton = (): void => {
    const eventProperties = { Class: "youtube" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://www.youtube.com/@favkorea`, "_blank");
  };
  const onClickTiktokButton = (): void => {
    const eventProperties = { Class: "tiktok" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://www.tiktok.com/@fav_korea`, "_blank");
  };
  const onClickKakaoButton = (): void => {
    const eventProperties = { Class: "kakao" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://pf.kakao.com/_KxgwWxj`, "_blank");
  };

  const onClickMBTIButton = (): void => {
    const eventProperties = { Class: "mbti" };
    amplitude.track("Click Button", eventProperties);
    window.open(`https://mbti.favapp.io`, "_blank");
  };

  const testClickListener = (): void => {
    console.log(faviewURLData);
  };

  return (
    !faviewURLDataLoading && (
      <MainPresenter
        onClickMainLogoButton={onClickMainLogoButton}
        onClickAppDownloadButton={onClickAppDownloadButton}
        onClickFAVHomepageButton={onClickFAVHomepageButton}
        onClickFAVTeampageButton={onClickFAVTeampageButton}
        onClickInstaCollabButton={onClickInstaCollabButton}
        onClickMBTIButton={onClickMBTIButton}
        onClickFAVInstagramButton={onClickFAVInstagramButton}
        onClickFAVIEWExpButton={onClickFAVIEWExpButton}
        onClickFAVIEWProposalButton={onClickFAVIEWProposalButton}
        onClickYoutubeButton={onClickYoutubeButton}
        onClickTiktokButton={onClickTiktokButton}
        onClickKakaoButton={onClickKakaoButton}
        testClickListener={testClickListener}
      />
    )
  );
};

export default MainContainer;
