import axios from "axios";
import { apiPaths } from "modules/defines/paths";

const api = axios.create({
  baseURL: apiPaths.awsBaseUrl,
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true,
});

export const linkAPI = {
  getLink: (query: string) => api.get(apiPaths.getLink + query),
  getFaviewSpace: () => api.get(apiPaths.getFaviewSpace),
};
