export const pagePaths = {
  main: "/",
  shop: "/shop",
  faview: "/faview",
};

export const apiPaths = {
  awsBaseUrl: "https://api.pkbroz.com/",
  // awsBaseUrl: "http://localhost:3030/",
  // Link
  getLink: "admin/link/",
  // FAVIEW
  getFaviewSpace: "faview/",
};
