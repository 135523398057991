import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import styled from "styled-components";
import { pagePaths } from "modules/defines/paths";
import MainContainer from "containers/MainContainer";
import FaviewContainer from "containers/FaviewContainer";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
`;
const RouterComponent = () => (
  <ContentWrapper>
    <Switch>
      <Route path={pagePaths.main} exact component={MainContainer} />
      {/* <Route path={pagePaths.faview} exact component={FaviewContainer} /> */}
      {/* <Redirect from="" to={pagePaths.main} /> */}
    </Switch>
  </ContentWrapper>
);

export default RouterComponent;
