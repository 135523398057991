import { palette } from "modules/defines/styles";
import styled, { createGlobalStyle } from "styled-components";
import LogoMain from "assets/img/logo_main.png";
import QRImage from "assets/img/qrcode_app_download.png";
import {
  icon_download,
  icon_home,
  icon_home_blue,
  icon_comment_blue,
  icon_comment,
  icon_link,
  icon_insta,
  icon_insta_white,
  icon_youtube_white,
  icon_tiktok_white,
  icon_faview,
  icon_test,
} from "assets/icon";
import PretendardBlack from "assets/fonts/pretendard/Pretendard-Black.woff";
import PretendardExtraBold from "assets/fonts/pretendard/Pretendard-ExtraBold.woff";
import PretendardBold from "assets/fonts/pretendard/Pretendard-Bold.woff";
import PretendardSemiBold from "assets/fonts/pretendard/Pretendard-SemiBold.woff";
import PretendardRegular from "assets/fonts/pretendard/Pretendard-Regular.woff";
import PretendardMedium from "assets/fonts/pretendard/Pretendard-Medium.woff";
import PretendardLight from "assets/fonts/pretendard/Pretendard-Light.woff";
import PretendardExtraLight from "assets/fonts/pretendard/Pretendard-ExtraLight.woff";
import PretendardThin from "assets/fonts/pretendard/Pretendard-Thin.woff";

import LINESeedKRBold from "assets/fonts/line_seed_sans/LINESeedKR-Bd.woff";
import LINESeedKRRegular from "assets/fonts/line_seed_sans/LINESeedKR-Rg.woff";
import LINESeedKRThin from "assets/fonts/line_seed_sans/LINESeedKR-Th.woff";

const FontStyle = createGlobalStyle`
  @font-face {
    font-family: "Pretendard-black";
    font-style: normal;
    src: url(${PretendardBlack}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-extrabold";
    font-style: normal;
    src: url(${PretendardExtraBold}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-bold";
    font-style: normal;
    src: url(${PretendardBold}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-semibold";
    font-style: normal;
    src: url(${PretendardSemiBold}) format("woff");
}
  @font-face {
    font-family: "Pretendard-regular";
    font-style: normal;
    src: url(${PretendardRegular}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-medium";
    font-style: normal;
    src: url(${PretendardMedium}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-light";
    font-style: normal;
    src: url(${PretendardLight}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-extralight";
    font-style: normal;
    src: url(${PretendardExtraLight}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-thin";
    font-style: normal;
    src: url(${PretendardThin}) format("woff");
  }
  @font-face {
    font-family: "LINESeedKR-Bold";
    font-style: normal;
    src: url(${LINESeedKRBold}) format("woff");
  }
  @font-face {
    font-family: "LINESeedKR-Regular";
    font-style: normal;
    src: url(${LINESeedKRRegular}) format("woff");
  }
  @font-face {
    font-family: "LINESeedKR-Thin";
    font-style: normal;
    src: url(${LINESeedKRThin}) format("woff");
  }
`;
const MainPresenter = ({
  onClickMainLogoButton,
  onClickAppDownloadButton,
  onClickFAVHomepageButton,
  onClickFAVTeampageButton,
  onClickInstaCollabButton,
  onClickMBTIButton,
  onClickFAVInstagramButton,
  onClickFAVIEWExpButton,
  onClickFAVIEWProposalButton,
  onClickYoutubeButton,
  onClickTiktokButton,
  onClickKakaoButton,
  testClickListener,
}: any) => {
  return (
    <Container>
      <FontStyle />
      <HeadContainer>
        <MainLogo src={LogoMain} onClick={onClickMainLogoButton} />
        <HeaderButtonContainer>
          <HompageBtn onClick={onClickFAVHomepageButton}>
            <HomeLogo src={icon_home_blue} />
            <HomepageBtnText>홈페이지</HomepageBtnText>
          </HompageBtn>
        </HeaderButtonContainer>
      </HeadContainer>

      <IntroContainer>
        <IntroText1>Discover & Experience your Favorites, FAV</IntroText1>
        <IntroText2 onClick={testClickListener}>
          당신을 위한 공간 취향안내서
        </IntroText2>
        <IntroText3>
          언제 어디서든, 내가 원하는 공간을 경험할 수 있게.
        </IntroText3>
      </IntroContainer>

      <ButtonContainer>
        <ButtonItemWrapper onClick={onClickAppDownloadButton}>
          <ButtonIcon src={icon_download} />
          <ButtonTextContainer>
            <ButtonText>FAV 앱 다운로드</ButtonText>
            <ButtonSubText>Android, iOS 앱</ButtonSubText>
          </ButtonTextContainer>
        </ButtonItemWrapper>
        <ButtonItemWrapper onClick={onClickFAVHomepageButton} web_hide={true}>
          <ButtonIcon src={icon_home} />
          <ButtonTextContainer>
            <ButtonText>FAV 홈페이지</ButtonText>
          </ButtonTextContainer>
        </ButtonItemWrapper>
        {/* <ButtonItemWrapper onClick={onClickFAVTeampageButton}>
          <ButtonIcon src={icon_link} />
          <ButtonTextContainer>
            <ButtonText>FAV를 소개합니다</ButtonText>
            <ButtonSubText>FAV 서비스 소개</ButtonSubText>
          </ButtonTextContainer>
        </ButtonItemWrapper> */}
        <ButtonItemWrapper onClick={onClickInstaCollabButton}>
          <ButtonIcon src={icon_insta} />
          <ButtonTextContainer>
            <ButtonText>인스타그램 협업 문의</ButtonText>
            <ButtonSubText>인스타그램 컨텐츠 협업 문의</ButtonSubText>
          </ButtonTextContainer>
        </ButtonItemWrapper>
        <ButtonItemWrapper onClick={onClickMBTIButton}>
          <ButtonIcon src={icon_test} />
          <ButtonTextContainer>
            <ButtonText>FAV 취향 테스트</ButtonText>
            <ButtonSubText>내 공간 취향 알아보러 가기</ButtonSubText>
          </ButtonTextContainer>
        </ButtonItemWrapper>
        <ButtonDividerWrapper>
          <ButtonDivider />
        </ButtonDividerWrapper>
        <ButtonItemWrapper onClick={onClickFAVIEWExpButton}>
          <ButtonIcon src={icon_faview} />
          <ButtonTextContainer>
            <ButtonText>FAVIEW 체험하기</ButtonText>
            <ButtonSubText>3D 공간뷰어 FAVIEW</ButtonSubText>
          </ButtonTextContainer>
        </ButtonItemWrapper>
        <ButtonItemWrapper onClick={onClickKakaoButton} web_hide={true}>
          <ButtonIcon src={icon_comment} />
          <ButtonTextContainer>
            <ButtonText>공간 촬영 문의</ButtonText>
          </ButtonTextContainer>
        </ButtonItemWrapper>
        <ButtonItemWrapper onClick={onClickFAVIEWProposalButton}>
          <ButtonIcon src={icon_download} />
          <ButtonTextContainer>
            <ButtonText>3D 공간 촬영제안서</ButtonText>
            <ButtonSubText>FAVIEW 촬영제안서 다운로드</ButtonSubText>
          </ButtonTextContainer>
        </ButtonItemWrapper>
      </ButtonContainer>
      <ButtonDescription>
        'favkorea' 인스타그램에 업로드된 공간은 FAVIEW 촬영을 무료로
        제공해드립니다. '촬영 문의'를 통해 문의바랍니다.
      </ButtonDescription>

      <BottomButtonContainer>
        <BottomButtonItem
          src={icon_insta_white}
          onClick={onClickFAVInstagramButton}
        />
        <BottomButtonItem
          src={icon_youtube_white}
          onClick={onClickYoutubeButton}
        />
        <BottomButtonItem
          src={icon_tiktok_white}
          onClick={onClickTiktokButton}
        />
      </BottomButtonContainer>

      <QRContainer>
        <HompageBtn onClick={onClickKakaoButton}>
          <HomeLogo src={icon_comment_blue} />
          <HomepageBtnText>촬영 문의</HomepageBtnText>
        </HompageBtn>
        <QRImageContainer src={QRImage} />
        <QRTextContainer>QR 앱 다운로드</QRTextContainer>
      </QRContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  min-width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
      106.61deg,
      rgba(255, 255, 255, 0.4) 0.64%,
      rgba(255, 255, 255, 0) 81.7%
    ),
    #0097ff;
  position: relative;
  @media screen and (max-height: 920px) {
    height: 1000px;
  }
  @media screen and (max-width: 1200px) {
    height: 100%;
  }
`;

////////// 1. HEADER //////////////////////////////

const HeadContainer = styled.div`
  margin-top: 60px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Pretendard-regular;
  @media screen and (max-width: 1200px) {
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 360px) {
    margin-top: 48px;
  }
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 200px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const MainLogo = styled.img`
  margin-left: 200px;
  height: 50px;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 360px) {
    height: 40px;
  }
`;

const HompageBtn = styled.div`
  margin-right: 16px;
  width: 160px;
  height: 50px;
  display: flex;
  border-radius: 40px;
  background-color: ${palette.white};
  font-size: 20px;
  font-weight: 700;
  color: ${palette.mainBlue};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HomeLogo = styled.img`
  height: 20px;
`;
const HomepageBtnText = styled.div`
  margin-left: 10px;
  font-size: 20px;
  line-height: 20px;
  color: ${palette.mainBlue};
  font-family: LINESeedKR-Bold;
`;
////////// 2. Intro //////////////////////////////
const IntroContainer = styled.div`
  margin-top: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 360px) {
    margin-top: 40px;
  }
`;
const IntroText1 = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-family: LINESeedKR-Bold;
  color: ${palette.white};
  @media screen and (max-width: 700px) {
    font-size: 12px;
    line-height: 12px;
  }
  @media screen and (max-width: 360px) {
    font-size: 11px;
    line-height: 11px;
  }
`;
const IntroText2 = styled.div`
  font-size: 48px;
  line-height: 48px;
  font-family: LINESeedKR-Bold;
  color: ${palette.white};
  margin-top: 24px;
  @media screen and (max-width: 700px) {
    font-size: 28px;
    line-height: 32px;
  }
  @media screen and (max-width: 360px) {
    font-size: 22px;
    line-height: 22px;
  }
`;
const IntroText3 = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-family: LINESeedKR-Regular;
  color: ${palette.white};
  margin-top: 16px;
  @media screen and (max-width: 700px) {
    font-size: 14px;
    line-height: 14px;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
    line-height: 12px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;
  width: 270px;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    margin-top: 48px;
    width: 220px;
  }
  @media screen and (max-width: 360px) {
    margin-top: 40px;
    width: 180px;
  }
`;
const ButtonItemWrapper = styled.div<{ web_hide?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 40px;
  background-color: ${palette.white};
  &:hover {
    background-color: ${palette.lightGray};
  }
  border: solid 1px #c5c5c5;
  cursor: pointer;
  margin-bottom: 18px;
  @media screen and (min-width: 1201px) {
    display: ${(props) => (props.web_hide ? "none" : "flex")};
  }
  @media screen and (max-width: 700px) {
    height: 48px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 360px) {
    height: 44px;
    margin-bottom: 14px;
  }
`;
const ButtonIcon = styled.img`
  width: 20px;
  height: 20px;
  @media screen and (max-width: 360px) {
    width: 14px;
    height: 14px;
  }
`;
const ButtonTextContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
`;
const ButtonText = styled.div`
  font-family: LINESeedKR-Bold;
  font-size: 18px;
  @media screen and (max-width: 700px) {
    font-size: 15px;
    line-height: 15px;
  }
  @media screen and (max-width: 360px) {
    font-size: 13px;
    line-height: 13px;
  }
`;
const ButtonSubText = styled.div`
  font-family: LINESeedKR-Regular;
  font-size: 11px;
  margin-top: 3px;
  color: ${palette.fontGray};
  @media screen and (max-width: 700px) {
    font-size: 9px;
    line-height: 9px;
  }
  @media screen and (max-width: 360px) {
    font-size: 8px;
    line-height: 8px;
  }
`;
const ButtonDividerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  @media screen and (max-width: 700px) {
    margin-bottom: 16px;
  }
`;
const ButtonDivider = styled.div`
  width: 200px;
  border-bottom: solid 1px ${palette.borderWhite};
  border-radius: 4px;
  @media screen and (max-width: 700px) {
    width: 160px;
  }
`;
const ButtonDescription = styled.div`
  color: ${palette.white};
  font-family: LINESeedKR-Regular;
  font-size: 12px;
  width: 320px;
  text-align: center;
  @media screen and (max-width: 700px) {
    width: 290px;
  }
  @media screen and (max-width: 360px) {
    width: 220px;
  }
`;

////////// 3. Bottom Button //////////////////////////////
const BottomButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 60px;
  @media screen and (max-width: 1200px) {
    position: static;
    margin-top: 60px;
    padding-bottom: 120px;
  }
`;
const BottomButtonItem = styled.img`
  height: 40px;
  margin: 0 28px;
  cursor: pointer;
  @media screen and (max-width: 360px) {
    height: 32px;
  }
`;

////////// 4. QR //////////////////////////////
const QRContainer = styled.div`
  position: absolute;
  right: 60px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
const QRImageContainer = styled.img`
  margin-top: 16px;
  width: 160px;
  height: 160px;
  padding: 0px;
`;
const QRTextContainer = styled.div`
  margin-top: 14px;
  width: 160px;
  font-family: LINESeedKR-Regular;
  font-size: 18px;
  padding: 8px 0px;
  border-radius: 10000px;
  border: solid 1px #fff;
  background-color: rgba(255, 255, 255, 0.3);
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MainPresenter;
