export const palette = {
  mainBlueLight: "#B3E0FF",
  mainBlue: "#0097FF",
  mainBlueHover: "#0067AD",
  white: "#FFFFFF",
  extraLightGray: "#FAFAFA",
  lightGray: "#E6E6E6",

  fontLightGray: "#aeaeae",
  fontBlack: "#1e1e1e",
  fontGray: "#777777",

  borderWhite: "rgba(255, 255, 255, 0.4)",
};
