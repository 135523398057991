import { useState, useEffect, useCallback } from "react";

type axiosReturn = {
  loading?: boolean;
  error?: string | null;
  data?: any;
  refetch?: any;
};

const useAxios = (
  axiosMethod: any,
  payload: string | null = null,
  errorMessage = ""
): axiosReturn => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null,
  });

  const [trigger, setTrigger] = useState(0);
  const refetch = useCallback(() => {
    setState((e) => ({ ...e, loading: true }));
    setTrigger(Date.now());
  }, []);

  if (!axiosMethod) {
    console.error("invalid axiosMethod parameter!");
    return { error: "invalid axiosMethod parameter!" };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    axiosMethod(payload)
      .then((data: any) => {
        setState((e) => ({ ...e, loading: false, data }));
      })
      .catch((error: any) => {
        if (errorMessage !== "") {
          alert(errorMessage);
        }
        setState((e) => ({ ...e, loading: false, error }));
      });
  }, [axiosMethod, errorMessage, payload, trigger]);
  return { ...state, refetch };
};

export default useAxios;
